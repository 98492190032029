<template>
  <vx-card title="Upload Invoice">
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Supplier</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="supplier"
          :options="optionSupplier"
          :multiple="false"
          :allow-empty="true"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Name"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.Code }} - {{ dt.option.Name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.Code }} - {{ dt.option.Name }}</span>
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-if="supplier == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Operating Unit</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="operating_unit"
          :options="optionOperatingUnit"
          :multiple="false"
          :allow-empty="true"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Name"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title"
                >{{ dt.option.Code }} - {{ dt.option.Name }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title"
                >{{ dt.option.Code }} - {{ dt.option.Name }}</span
              >
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-if="operating_unit == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Operating Unit Territory</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="operating_unit_territory"
          :options="optionOperatingUnitTerritory"
          :multiple="false"
          :allow-empty="true"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Name"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title"
                >{{ dt.option.Territory.code }} -
                {{ dt.option.Territory.name }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title"
                >{{ dt.option.Territory.code }} -
                {{ dt.option.Territory.name }}</span
              >
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-if="operating_unit_territory == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <!-- <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Warehouse</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="warehouse"
          :options="optionWarehouse"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="name"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.name }}</span>
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-if="warehouse == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Purchase Order</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="po"
          :options="optionPO"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="id"
          label="label"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{
                dt.option.sources == "Import" ? dt.option.code : dt.option.label
              }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{
                dt.option.sources == "Import" ? dt.option.code : dt.option.label
              }}</span>
            </div>
          </template>
        </multiselect>
        <span class="text-danger text-sm" v-if="po == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Good Receive</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="grSelected"
          :options="optionGR"
          :multiple="true"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="id"
          label="label"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{
                dt.option.sources == "Import" ? dt.option.code : dt.option.label
              }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{
                dt.option.sources == "Import" ? dt.option.code : dt.option.label
              }}</span>
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-if="grSelected == null && status_submit"
          >This field is required</span
        >
      </div>
    </div> -->
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>File JSON</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <input
          class="inputx"
          type="file"
          v-if="uploadReady"
          ref="file"
          name="file"
          accept=".json"
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Attachment</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <input
          class="inputx"
          type="file"
          v-if="uploadReady"
          ref="file_attachment"
          name="file_attachment"
          accept=".pdf"
          multiple
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-button class="ml-auto mt-2" v-on:click="handleSubmit"
          >Submit</vs-button
        >
      </div>
    </div>

    <div class="vx-row mb-12">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <vs-table
          search
          stripe
          border
          description
          :sst="true"
          :data="table.data"
          :max-items="table.length"
          :total="table.total"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="
                  p-3
                  border border-solid
                  d-theme-border-grey-light
                  rounded-full
                  d-theme-dark-bg
                  cursor-pointer
                  flex
                  items-center
                  justify-between
                  font-medium
                "
              >
                <span class="mr-2"
                  >{{ this.table.start }} - {{ this.table.end }} of
                  {{ this.table.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in table.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          <template slot="thead">
            <vs-th>No</vs-th>
            <vs-th>Description</vs-th>
            <!-- <vs-th sort-key="params">Total Item</vs-th> -->
            <vs-th sort-key="file">File JSON</vs-th>
            <vs-th sort-key="date">Date</vs-th>
            <vs-th>Detail</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>{{ indextr + 1 }}</vs-td>
              <vs-td :data="data[indextr].description">
                Supplier : {{ data[indextr].description }}<br />
                User : {{ data[indextr].username }}
              </vs-td>
              <!-- <vs-td :data="data[indextr].total_data">
                {{ data[indextr].total_data }}
              </vs-td> -->
              <vs-td :data="data[indextr].file">
                <!-- <a href="#" v-on:click="downloadFileAwsS3(tr.file)">Download File</a> -->
                <a href="#" v-on:click="downloadFileAwsS3(data[indextr].file)">{{ data[indextr].file.split("/").pop() }}</a>
              </vs-td>
              <vs-td :data="data[indextr].time">{{
                dateFormat(data[indextr].time)
              }}</vs-td>
              <vs-td>
                <label>
                  <feather-icon
                    icon="CheckSquareIcon"
                    svgClasses="h-4 w-4"
                    style=""
                    class="ml-1"
                  />{{
                    data[indextr].document_success
                  }}
                </label>
                <feather-icon
                  icon="XSquareIcon"
                  svgClasses="h-4 w-4"
                  class="ml-1"
                />
                {{
                  data[indextr].document_failed
                }}
                <br />
                <a
                  ><vs-button
                    class="mt-2 ml-2"
                    color="primary"
                    type="border"
                    icon-pack="feather"
                    icon="icon-search"
                    v-on:click="showDetail(indextr)"
                  ></vs-button
                ></a>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          style="padding-top: 5px"
          :total="table.totalPage"
          v-model="setPage"
        />
      </div>
      <!-- detail-->
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail
              ? ' nonfixed vx-col md:w-1/2 w-full mb-base vertical-divider'
              : '',
            detailHide,
          ]"
        >
          <div>
            <vs-button
              size="small"
              v-on:click="closeDetail"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
              style="margin-bottom: 2%"
              >Close</vs-button
            >
          </div>
          <vs-list>
            <vs-list-item
              v-for="(item, indexItem) in selected.detail_json_invoice"
              :key="item.ID"
              :subtitle="
                indexItem + 1 + '. ' + item.Status + ' : ' + item.Description
              "
            ></vs-list-item>
          </vs-list>
        </div>
      </transition>
    </div>
  </vx-card>
</template>
<script>
import moment from "moment";
export default {
  components: {},
  data() {
    return {
      uploadReady: true,
      optionPO: [],
      optionGR: [],
      optionSupplier: [],
      optionOperatingUnit: [],
      optionOperatingUnitTerritory: [],
      optionWarehouse: [],
      po: null,
      grSelected: null,
      gr: [],
      supplier: null,
      warehouse: null,
      status_submit: false,
      dn_date: null,
      received_date: null,
      gr_ids: null,
      gr_codes: null,
      gr_order_number: null,
      operating_unit: null,
      operating_unit_territory: null,
      detail: false,
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      selected: {},
      table: {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      },
      period: {},
      postingDate: null,
      optionPeriod: [{}],
      disabledDates: {},
    };
  },
  methods: {
    dateFormat(value) {
      let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      // console.log(timezone)
      return moment(value).toLocaleString("en-US", {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
      // return moment(value).zone(timezone).format('YYYY-MM-DD HH:mm:ss')
    },
    handleSubmit() {
      this.$vs.loading();
      console.log("handle submit");
      var file = this.$refs.file.files[0];
      var file_attachment = this.$refs.file_attachment.files;
      let statusUpload = true
      for (let i = 0; i < file_attachment.length; i++) {
        if (file_attachment[i].size > 5000000) {
          // 5mb
          this.$vs.notify({
            title: "Error",
            text: file_attachment[i].name + " is greater than 5MB",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          statusUpload = false
        }
      }

      if (statusUpload) {
        if (this.supplier == null) {
          this.$vs.notify({
            color: "danger",
            title: "Form Validation",
            text: "Supplier is required",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          this.getData();
          // this.$vs.loading.close();
        } else if (file == null) {
          // this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Form Validation",
            text: "File JSON is required",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          this.getData();
        } else if (file_attachment.length == 0) {
          // this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Form Validation",
            text: "File Attachment is required",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          this.getData();
        } else {
          this.uploadReady = false;
          this.$nextTick(() => {
            this.uploadReady = true;
          });
          this.formData = new FormData();
          var dateNow = new Date();
          var now =
            dateNow.getFullYear() +
            "" +
            (dateNow.getMonth() + 1) +
            "" +
            dateNow.getDate();
          now +=
            +"" +
            dateNow.getHours() +
            "" +
            dateNow.getMinutes() +
            "" +
            dateNow.getSeconds();

          this.formData.append("user_agent", navigator.userAgent);
          this.formData.append("url", "/api/v1/pi/upload-json");
          this.formData.append("supplier_id", this.supplier.ID);
          this.formData.append("operating_unit_id", this.operating_unit.ID);
          this.formData.append("operating_unit_code", this.operating_unit.Code);
          // this.formData.append("territory_id", this.$userLogin.territory_id);
          // this.formData.append("territory_area_id",this.$userLogin.territory_area_id);
          this.formData.append(
            "territory_id",
            this.operating_unit_territory.Territory.ID
          );
          this.formData.append(
            "territory_area_id",
            this.operating_unit_territory.Territory.territory_area_id
          );
          this.formData.append("file_name", now + "_" + file.name);
          this.formData.append("file", file);

          this.$http
            .post("/api/v1/pi/upload-json", this.formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((resp) => {
              if (resp.status == "success") {
                var message = "";
                this.$vs.notify({
                  color: "success",
                  title: "Success",
                  text: message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
                this.getData();
                this.piId = parseInt(resp.data.pi_id);
                for (let i = 0; i < file_attachment.length; i++) {
                  if (file_attachment[i].size > 5000000) {
                    // 5mb
                    this.$vs.notify({
                      title: "Error",
                      text: file_attachment[i].name + " is greater than 5MB",
                      color: "danger",
                      position: "top-right",
                      iconPack: "feather",
                      icon: "icon-check",
                    });
                  } else {
                    this.file = file_attachment[i];
                    this.uploadData();
                  }
                }
                // this.optionSupplier = [];
                // this.optionWarehouse = [];
                // this.optionPO = [];
                // this.optionGR = [];
                // this.supplier = null;
                // this.warehouse = null;
                // this.po = null;
                // this.received_date = null;
                // this.dn_date = null;
                // this.gr_order_number = null;
                // this.gr_ids = null;
                // this.gr_codes = null;
                // this.grSelected = null;
              } else {
                this.$vs.notify({
                  color: "danger",
                  title: "Error",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
                this.getData();
              }
            })
            .catch((error) => {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: error,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
              this.getData();
            });
        }
      } else {
        this.getData()
      }
    },
    paramUpload() {
      var form = new FormData();
      form.append("pi_id", this.piId);
      form.append("file", this.file);
      return form;
    },
    uploadData() {
      this.$vs.loading();
      this.$http
        .post("/api/v1/pi/upload-file", this.paramUpload(), {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getOptionSupplier() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/suppliers", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionSupplier = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Supplier option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionOperatingUnit() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/setting/operating-unit", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionOperatingUnit = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Operating Unit option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionOperatingUnitTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/operating-unit-territory", {
          params: {
            order: "id",
            sort: "asc",
            operating_unit_id: this.operating_unit.ID,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionOperatingUnitTerritory = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Operating Unit Territory option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    closeDetail() {
      this.detail = false;
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    showDetail(index) {
      this.selected = this.table.data[index];
      this.detail = true;
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/import/history", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            type: "import-json-invoice",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            console.log(resp.message);
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    //   getOptionWarehouse() {
    //     this.$vs.loading();
    //     this.$http
    //       .get("/api/v1/master/warehouses", {
    //         params: {
    //           order: "name",
    //           sort: "asc",
    //         },
    //       })
    //       .then((resp) => {
    //         if (resp.code == 200) {
    //           this.optionWarehouse = resp.data.records;
    //         } else {
    //           this.$vs.notify({
    //             title: "Error",
    //             text: "Failed to get Warehouse option",
    //             color: "danger",
    //             position: "top-right",
    //             iconPack: "feather",
    //             icon: "icon-check",
    //           });
    //         }
    //         this.$vs.loading.close();
    //       });
    //   },
    //   getOptionPurchaseOrder() {
    //     this.$vs.loading();
    //     this.$http
    //       .get("/api/v1/pi/list-po", {
    //         params: {
    //           supplier_id: this.supplier.ID,
    //           warehouse_id: this.warehouse.ID,
    //         },
    //       })
    //       .then((resp) => {
    //         if (resp.code == 200) {
    //           this.optionPO = resp.data.records;
    //           console.log(resp.data.records);
    //         } else {
    //           this.$vs.notify({
    //             title: "Error",
    //             text: "Failed to get PO option",
    //             color: "danger",
    //             position: "top-right",
    //             iconPack: "feather",
    //             icon: "icon-check",
    //           });
    //           this.warehouse = null;
    //         }
    //         this.$vs.loading.close();
    //       });
    //   },
    //   getOptionPurchaseReceive() {
    //     this.$vs.loading();
    //     this.$http
    //       .get("/api/v1/pi/list-gr", {
    //         params: {
    //           purchase_order_id: this.po.id,
    //         },
    //       })
    //       .then((resp) => {
    //         if (resp.code == 200) {
    //           this.optionGR = resp.data.records;
    //           this.grSelected = resp.data.records;
    //           console.log(resp.data.records);
    //         } else {
    //           console.log(resp);
    //           this.$vs.notify({
    //             title: "Error",
    //             text: "Failed to get GR option",
    //             color: "danger",
    //             position: "top-right",
    //             iconPack: "feather",
    //             icon: "icon-check",
    //           });
    //         }
    //         this.$vs.loading.close();
    //       });
    //   },
  },
  mounted() {
    this.getOptionSupplier();
    this.getOptionOperatingUnit();
    // this.getOptionWarehouse();
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  watch: {
    operating_unit: function () {
      this.getOptionOperatingUnitTerritory();
    },
    operating_unit_territory: function () {
    
    },
    // supplier: function () {
    //   this.po = null;
    //   this.warehouse = null;
    //   if (this.warehouse != null) {
    //     this.getOptionPurchaseOrder();
    //   }
    // },
    // warehouse: function () {
    //   if (this.supplier != null) {
    //     this.getOptionPurchaseOrder();
    //   }
    // },
    // po: function () {
    //   if (this.po != null) {
    //     this.getOptionPurchaseReceive();
    //     this.gr = null;
    //     this.po_code_external = this.po.external_code;
    //   }
    // },
    // grSelected: function () {
    //   if (this.grSelected != null) {
    //     let gr_id = [];
    //     let gr_code = [];
    //     let gr_date = [];
    //     let gr_dn_date = [];
    //     let gr_do_number = [];
    //     this.grSelected.map(function (obj) {
    //       gr_id.push(obj.id);
    //       gr_code.push(obj.code + " ("+obj.delivery_order_number+")");
    //       gr_date.push(obj.date);
    //       gr_dn_date.push(obj.dn_date);
    //       gr_do_number.push(obj.delivery_order_number);
    //     });
    //     this.dn_date = gr_dn_date
    //       .map(function (e) {
    //         return e;
    //       })
    //       .sort()
    //       .reverse()[0];
    //     this.received_date = gr_date
    //       .map(function (e) {
    //         return e;
    //       })
    //       .sort()
    //       .reverse()[0];
    //     this.gr_order_number = gr_do_number.join();
    //     this.gr_ids = gr_id.join();
    //     this.gr_codes = gr_code.join();
    //   }
    // },
  },
};
</script>
<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
.core-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.highlight > td {
  background-color: #d0cdf0;
}

.con-vs-checkbox {
  margin-left: auto;
  margin-right: auto;
}

.nonfixed {
  position: inherit;
  padding-left: 20px;
}

.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>